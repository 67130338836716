/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.header-block {
  z-index: 100; }

.app-body[dir="rtl"] .email_img {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.blocked {
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  margin: 0%; }

.blocked-container-card {
  background-color: #191919;
  height: 100%;
  padding: 90px 160px 24px;
  border-radius: 25px;
  overflow: hidden;
  line-height: 7em;
  position: relative;
  z-index: 10; }

.blocked-container {
  margin-top: 50px; }

.blocked-container .blocked_text1 {
  color: #ee7623;
  font-family: "changa" !important;
  text-transform: uppercase;
  margin-top: -18%;
  text-align: center;
  line-height: 1em;
  font-weight: bold;
  font-size: 22px; }

.contentLeft {
  margin-top: 0%; }

.blocked_text2 {
  color: white;
  font-family: "changa" !important;
  text-align: center;
  line-height: 5.5em;
  font-weight: bold;
  font: changa; }

.blocked_text3 {
  color: #818181;
  text-align: center;
  line-height: 1.5em !important;
  font-family: "changa" !important;
  text-overflow: ellipsis; }

.blocked_text3 span {
  display: none; }

.blocked_text3.blocked_text5 {
  margin-bottom: 40px; }

.blocked-text5 {
  color: #818181;
  text-align: center;
  line-height: 1.5em;
  margin-top: -10%;
  font-family: "changa" !important; }

.blocked_text6 {
  color: white;
  text-align: center;
  font-family: "changa" !important;
  margin: -11px; }

.logo-container {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 0px; }

.logo {
  width: auto;
  height: 72px;
  cursor: pointer; }

.blocked_email {
  height: 29px;
  width: 71%;
  border: none;
  outline: 0;
  padding: 10px;
  font-family: "changa" !important;
  margin: 0px 17px;
  font-size: 15px; }

.blocked_text4 {
  color: white;
  text-align: center;
  font-family: "changa" !important;
  margin: 8px; }

.blocked_form {
  background: #fff;
  border-radius: 50px;
  padding: 0;
  line-height: 1;
  width: 40%;
  margin: -22px auto 0 auto;
  display: flex;
  position: relative;
  z-index: 100;
  overflow: hidden; }

.blocked-container > * {
  line-height: 4em;
  font-weight: 300;
  margin: 10px; }

.error-container {
  margin: 0px 174px 0px 190px;
  font-size: 10px; }

@media screen and (max-width: 767px) {
  .blocked-text5 {
    margin-top: 0; }
  .blocked_text3 {
    line-height: 1.5em !important; }
  .blocked-container > * {
    line-height: 1em; }
  .blocked-container-card {
    width: 69%;
    margin: 0 auto;
    padding: 90px 10px 24px; }
  .error-message {
    margin: 0; }
  .blocked_form {
    line-height: 1em; }
  .email_img {
    margin: 11px; }
  .blocked_text3 span {
    display: inline; }
  .blocked_text5 {
    display: none; } }

.error-message {
  color: #bd0000;
  font-style: normal;
  padding: 4px;
  margin-top: 0;
  margin-bottom: 6px;
  font-weight: 400;
  height: 17px;
  font-size: 12px;
  position: relative; }

@media screen and (max-width: 480px) {
  .blocked-container .blocked_text2 {
    line-height: 1.5em;
    margin: 32px 0; }
  .error-container {
    margin: 0px 99px 11px 103px; } }

.language_button {
  border: 1px solid #707070;
  border-radius: 8px;
  font-family: changa;
  padding: 0px 17px !important;
  font-size: 18px;
  color: #707070; }
