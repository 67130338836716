/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

 @import "app/resources/styles/variables.scss";

 .app-body[dir="rtl"] {
   .header {
     .langButton {
       margin: 0px 0px 0 8px;
       .icon {
         margin: 0;
       }
     }
 
     .lanchange-btn {
       margin: 0px 0px 0 15px;
       .icon {
         margin: 0;
       }
     }
     
     .vertical-separator {
       margin: -3px 9px;
     }
   }
 }
 
 .langButton {
   color: transparent;
   display: inline-block;
   background: transparent;
   padding: 0;
   border-radius: 50%;
   font-size: $language-button-font-size;
   border: 2px solid;
   margin: 5px 11px;
   height: 44px;
   width: 44px;
   overflow: hidden;
   &:hover {
     background-color: transparent;
   }
   .icon {
     width: 100%;
     height: 100%;
     margin: 0;
     top: 0;
   }
 }
 