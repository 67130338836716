/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
@media (max-width: 745px) {
  .app-body[dir="rtl"] .contact-us .contact-us-container .contact-us-title-wrapper .contact-us-title {
    text-align: center; } }

.app-body[dir="rtl"] .contact-us .contact-us-container .contact-us-details .contact-us-email .email-error,
.app-body[dir="rtl"] .contact-us .contact-us-container .contact-us-details .contact-us-email .mobile-error,
.app-body[dir="rtl"] .contact-us .contact-us-container .contact-us-details .contact-us-mobile .email-error,
.app-body[dir="rtl"] .contact-us .contact-us-container .contact-us-details .contact-us-mobile .mobile-error,
.app-body[dir="rtl"] .contact-us .contact-us-container .contact-us-details .contact-us-subject .email-error,
.app-body[dir="rtl"] .contact-us .contact-us-container .contact-us-details .contact-us-subject .mobile-error {
  margin: 2px 0; }

.app-body[dir="rtl"] .contact-us .contact-us-container .contact-us-details .contact-us-btn {
  height: 45px; }

.contact-us {
  background: black; }
  .contact-us .contact-us-container {
    display: inline-block;
    background: black;
    color: #fff;
    min-height: calc(100vh - 60px - 185px);
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 745px) {
      .contact-us .contact-us-container {
        min-height: calc(100vh - 60px - 175px); } }
    .contact-us .contact-us-container .contact-us-title-wrapper {
      width: calc(100% - 25vw);
      margin: 60px 0 40px; }
      .contact-us .contact-us-container .contact-us-title-wrapper .contact-us-title {
        width: 100%;
        height: 25px;
        font-family: "changa";
        font-size: 20px;
        font-weight: 700;
        text-align: center; }
    .contact-us .contact-us-container .contact-us-details {
      width: calc(100% - 25vw);
      font-family: "changa"; }
      .contact-us .contact-us-container .contact-us-details .label {
        height: 19px;
        color: #5d5d5d;
        font-size: 18px; }
      .contact-us .contact-us-container .contact-us-details .contact-us-subject {
        width: 100%;
        margin-top: 5px; }
        .contact-us .contact-us-container .contact-us-details .contact-us-subject .select-box-container {
          width: 100%;
          margin: 0 0 25px; }
          .contact-us .contact-us-container .contact-us-details .contact-us-subject .select-box-container .select-box {
            width: 100%;
            border-radius: 5px; }
            .contact-us .contact-us-container .contact-us-details .contact-us-subject .select-box-container .select-box .selected {
              border-radius: 5px; }
        .contact-us .contact-us-container .contact-us-details .contact-us-subject .open {
          border-radius: 5px 5px 0 0; }
          .contact-us .contact-us-container .contact-us-details .contact-us-subject .open .select-box-container .select-box .selected {
            border-radius: 5px 5px 0 0; }
      .contact-us .contact-us-container .contact-us-details .contact-us-content {
        margin-top: 5px; }
      .contact-us .contact-us-container .contact-us-details .contact-us-email,
      .contact-us .contact-us-container .contact-us-details .contact-us-mobile {
        height: 53px;
        border-radius: 5px;
        border: 2px solid #4a4a4a;
        margin-top: 5px;
        margin-bottom: 25px; }
        .contact-us .contact-us-container .contact-us-details .contact-us-email input,
        .contact-us .contact-us-container .contact-us-details .contact-us-mobile input {
          width: 98%;
          height: 45px;
          color: #fff;
          font-size: 18px;
          overflow: hidden; }
        .contact-us .contact-us-container .contact-us-details .contact-us-email input:-webkit-autofill,
        .contact-us .contact-us-container .contact-us-details .contact-us-email input:-webkit-autofill:active,
        .contact-us .contact-us-container .contact-us-details .contact-us-email input:-webkit-autofill:focus,
        .contact-us .contact-us-container .contact-us-details .contact-us-email input:-webkit-autofill:hover,
        .contact-us .contact-us-container .contact-us-details .contact-us-mobile input:-webkit-autofill,
        .contact-us .contact-us-container .contact-us-details .contact-us-mobile input:-webkit-autofill:active,
        .contact-us .contact-us-container .contact-us-details .contact-us-mobile input:-webkit-autofill:focus,
        .contact-us .contact-us-container .contact-us-details .contact-us-mobile input:-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 30px #000 inset !important;
          -webkit-text-fill-color: #fff !important; }
        .contact-us .contact-us-container .contact-us-details .contact-us-email .email-error,
        .contact-us .contact-us-container .contact-us-details .contact-us-email .mobile-error,
        .contact-us .contact-us-container .contact-us-details .contact-us-mobile .email-error,
        .contact-us .contact-us-container .contact-us-details .contact-us-mobile .mobile-error {
          display: block;
          color: #5d5d5d;
          margin: 6px 0; }
      .contact-us .contact-us-container .contact-us-details .comment-textarea {
        height: 150px;
        background: black;
        color: white;
        width: 100%;
        border-radius: 5px;
        padding: 6px 9px;
        font-size: 18px;
        border: 2px solid #4a4a4a;
        margin-bottom: 25px; }
      .contact-us .contact-us-container .contact-us-details .contact-us-btn {
        margin-bottom: 25px;
        text-align: center; }
        .contact-us .contact-us-container .contact-us-details .contact-us-btn .btn {
          width: 247px;
          height: 47px;
          border-radius: 5px;
          background-color: #ff6d02; }
    .contact-us .contact-us-container .contactResponseData {
      color: #fff;
      margin-bottom: 44px;
      text-align: center; }
