/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.mobile-verification-container {
  background: black;
  color: #fff;
  min-height: calc(100vh - 80px - 185px); }
  .mobile-verification-container .desktop-icon {
    display: flex;
    margin: 35px auto 10px; }
  .mobile-verification-container .mobile-verification-input {
    margin: 0 auto;
    width: 330px;
    height: 65px; }
    .mobile-verification-container .mobile-verification-input .password-hide-wrapper {
      position: relative;
      float: left; }
      .mobile-verification-container .mobile-verification-input .password-hide-wrapper .input {
        width: 71px;
        height: 65px;
        border-radius: 5px;
        border: 2px solid #4a4a4a;
        text-align: center; }
      .mobile-verification-container .mobile-verification-input .password-hide-wrapper .password-hide-design {
        width: 23px;
        height: 21px;
        background: white;
        position: absolute;
        left: 30px;
        top: 22px;
        border-radius: 16px;
        pointer-events: none;
        display: none; }
  .mobile-verification-container .mobile-verification-content {
    width: 100%;
    text-align: center;
    padding-bottom: 4%;
    padding-top: 5%; }
    .mobile-verification-container .mobile-verification-content p {
      text-align: center;
      padding-bottom: 18px; }
    @media (max-width: 745px) {
      .mobile-verification-container .mobile-verification-content {
        width: 80%;
        margin: auto; } }
  .mobile-verification-container .btn-container {
    display: flex;
    padding-bottom: 16%;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    width: 100%; }
    .mobile-verification-container .btn-container .resend-link {
      font-size: 18px;
      text-decoration-line: inherit;
      margin-bottom: 50px;
      cursor: pointer;
      text-transform: uppercase; }
    .mobile-verification-container .btn-container .resend-code {
      font-size: 18px;
      text-decoration-line: inherit;
      margin-bottom: 50px;
      cursor: pointer;
      text-transform: uppercase;
      text-align: center; }
      .mobile-verification-container .btn-container .resend-code .resend-text {
        color: gray;
        text-decoration: underline;
        font-weight: 500;
        cursor: pointer; }
        .mobile-verification-container .btn-container .resend-code .resend-text.active {
          color: white;
          cursor: pointer; }
      .mobile-verification-container .btn-container .resend-code .timer {
        color: #5d5d5d;
        margin: 0 5px; }
      @media (max-width: 745px) {
        .mobile-verification-container .btn-container .resend-code {
          font-size: 12px; } }
    .mobile-verification-container .btn-container .btn-verify {
      width: 280px;
      height: 46px;
      border-radius: 5px;
      background-color: #ff740f; }
