/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
 $header-height: 70px;
@import "app/resources/styles/variables.scss";
@import "app/App.scss";


.app-body[dir="rtl"] {
  .app-menu {
    .app-menu-item-container {
      margin: 0;
      // margin-right: 40px;
      margin-top: 15px;
      height: calc(100% - 15px);
      padding-right: 30px;
      padding-left: 0;
      @media only screen and(max-width:745px){
        margin-right: -10px !important;
      }
    }

    .menu-sign-in {
      .select-box-elements {
        left: auto;
        right: 30px;
      }
      .user-menu-container {
        .user-menu-tab {
          > span {
            padding: 4px 8px 4px 2px;
            margin-right: 0;
          }
          .listing-filters {
            .select-box-container {
              // margin: 10px 0 10px -7px;
            }
          }
        }
      }
    }
  }

  .sidemenu {
    right: -456px;
    left: inherit;

    &.show {
      right: 0px;
    }
  }
// .marathon-5{
//   width: 60px;
//    height: 60px;
//   border-radius: 40px;
//   padding-left: 0px;
//   color: #757575;
//   font: normal normal 300 16px/30px Changa;
// }
  .sidemenu span {

    &.closebtn {
      top: 0;
      left: 0;
      right: auto;
      margin-left: 25px;
    }
  }
  .settings-btn {
    left: 20px;
    right: unset;
    @media screen and(max-width:768px) {
      left:75px;
      
    }
  }
}

.app-menu {
  @extend .scroll-bar;
  padding-top: 0;
  top: 70px;
  height: calc(100% - 70px);
  font-family: "changa";

  .app-menu-item-container {
    width: 200px;
    margin: 25px 0 0 9px;
    height: calc(100% - 25px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-left: 32px;

    a {
      text-decoration: none;
    }
  }
  .user-menu .user-menu-tab > span {
    padding: 0;
    color: #fff;
  }
  .menu-sign-in {
    //display: none;
    display: -webkit-flex;
    display: flex;
    width: calc(456px - 50px);
    padding: 0;
    padding-bottom: 13px;
    border-bottom: solid 2px grey;
    position: relative;
    margin-bottom: 10px;
    .icon {
      width: 38px;
      height: 38px;
      margin-left: 0;
    }

    .btn {
      padding: 6px 3px;
      margin: 0;

      &.sign-in-btn {
        margin: 0 5px;
      }

      &:hover {
        background-color: transparent;
      }

      span {
        padding: 0;
        cursor: pointer;
      }
    }
    .listing-filters {
      position: absolute;
      right: -15px;
      width: 10px;
      left: auto;
    }
  }

  .menuItem span {
    padding: 8px 0;
    height: 40px;
    display: block;
    font-size: 16px;
    -webkit-margin-before: 0;
    -webkit-margin-after: 17px;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    font-weight: initial;
    color: $app-menu-item-color;
    font-family: "changa";
    @media screen and(max-width:768px) {
      
      
    }

    &:hover {
      background-color: $menu-menuitem-background-color;
      color: #fff;
    }
  }

  .static-menu {
    margin-bottom: 10px;
    // padding-bottom: 60px;
    .menuItem span {
      color: $static-menu-item-color;

      &:hover {
        background-color: transparent;
        color: #fff;
      }
    }
    .z5-menu-text {
      color: $primary-orange-color;
    }
    .languagebtn-container{
      display:none;
    }
    @media screen and(max-width:768px) {
    .languagebtn-container{
      display:block;
    }
    .languagebtn-container .lanchange-btn{
        margin-right: 8px !important;
        span{
          padding: 0px;
        }
        
    }
  }
  }

  &.is-touch {
    .menuItem span {
      &:hover {
        background-color: none;
        color: #fff;
      }
    }
  }

  .settings-btn{
    position: absolute;
    right: 20px;
    top: 5px;
    @media screen and(max-width:768px) {
      right:75px;
      
    }
   
    &.btn{
      margin: auto;
     
    }
    .icon{
      width: 28px;
      height: 28px;
    }
   
  }
}

/*Device width pixel lesser than 860px */
@media only screen and (max-width: 1023px) {
  .static-menu {
    margin-bottom: 10px;
    padding-bottom: 60px;
  }
  .app-menu .menu-sign-in {
    padding-bottom: 0;
    margin-bottom: 20px;
  }
  .head-links-mobile {
    margin-bottom: 15px;
    padding: 3px;
    width: 350px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .app-body[dir="rtl"] .app-menu .app-menu-item-container {
    margin-top: 25px;
}
  .app-menu {
    .menu-sign-in {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      .user-menu-container {
        .user-menu-tab {
          .listing-filters {
            .select-box-container {
              margin: 10px 0 10px -7px;
            }
          }
        }
      }
      .select-box-elements {
        left: -142px;
      }
    }
  }
}

/*Device width pixel lesser than 745*/
@media only screen and (max-width: 745px) {
  // .sidemenu.show {
  //   top: 70px;
  // }

  .app-menu {
    bottom: 0;
    top: 160px;
    height: 100%;
    .menuItem span {
      &:hover {
        background-color: transparent;
        color: $menu-item-color;
      }
    }
    .app-menu-item-container {
      height: 100%;
      padding-left:11px;
    }
    &.is-touch {
      .menuItem span {
        &:hover {
          background-color: none;
          color: #fff;
        }
      }
    }
  }
}
@media only screen and (max-width: 1023px) {
  .app-menu {
    top: 160px;
  }
}
.static-menu1 {
  a {
    display: flex;
    align-items: center;
    width: 390px !important;

    span {
      padding-left: 8px;
      height: 40px;
      display: block;
      font-size: 16px;
      -webkit-margin-before: 0;
      -webkit-margin-after: 17px;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
      font-weight: initial;
      color: #929090;
      font-family: "changa";
    }
  }
}
