/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .user-menu-container .user-menu-tab {
  margin-left: 8px; }
  .app-body[dir="rtl"] .user-menu-container .user-menu-tab .listing-filters .select-box-container {
    margin: 13px 0 10px -15px; }
    .app-body[dir="rtl"] .user-menu-container .user-menu-tab .listing-filters .select-box-container .btn .icon {
      top: -2px; }

.app-body[dir="rtl"] .app-menu-item-container .user-menu-container .user-menu-tab .listing-filters {
  left: -15px;
  right: auto; }

.app-body[dir="rtl"] .header .user-menu-container .user-menu-tab .listing-filters {
  right: -15px;
  left: auto; }

.user-menu {
  position: relative;
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  left: 15px; }
  .user-menu .user-menu-tab {
    display: flex;
    align-items: center;
    position: relative; }
    @media (max-width: 1023px) {
      .user-menu .user-menu-tab {
        flex-direction: row-reverse;
        padding: 0;
        margin-right: 0; }
        .user-menu .user-menu-tab > span {
          padding: 0;
          margin-right: 0;
          color: #fff; } }
    .user-menu .user-menu-tab .listing-filters {
      position: absolute;
      left: -15px;
      width: 10px; }
      .user-menu .user-menu-tab .listing-filters .select-box-container {
        margin: 0;
        display: -ms-inline-flexbox;
        display: inline-flex; }
        .user-menu .user-menu-tab .listing-filters .select-box-container .select-box {
          width: 10px;
          position: relative;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
          flex-direction: column;
          cursor: pointer;
          color: #9993a0;
          z-index: 4;
          background: transparent; }
          .user-menu .user-menu-tab .listing-filters .select-box-container .select-box .select-box-down-arrow {
            margin: 0;
            padding: 0; }
          .user-menu .user-menu-tab .listing-filters .select-box-container .select-box .select-box-down-arrow {
            display: -ms-flexbox;
            display: -webkit-box;
            display: flex;
            -ms-flex-direction: row;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            -ms-flex-pack: justify;
            -webkit-box-pack: justify;
            justify-content: space-between;
            line-height: 46px; }
        .user-menu .user-menu-tab .listing-filters .select-box-container label {
          height: 46px;
          line-height: 46px;
          float: left; }
        .user-menu .user-menu-tab .listing-filters .select-box-container .btn {
          padding-right: 0px; }
          .user-menu .user-menu-tab .listing-filters .select-box-container .btn .icon {
            border: none;
            z-index: 3;
            width: 8px;
            height: auto;
            transition: transform 0.2s ease;
            top: 2px; }
          .user-menu .user-menu-tab .listing-filters .select-box-container .btn:hover {
            background-color: transparent; }
      .user-menu .user-menu-tab .listing-filters select-box:first-child {
        display: flex; }
      .user-menu .user-menu-tab .listing-filters select-box .select-box-elements {
        min-width: 185px;
        width: 175px;
        height: auto;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        transition: height 0.3s ease;
        background-color: #ebebeb;
        color: black;
        overflow: hidden;
        transition: height 0.3s ease;
        margin-left: 11px;
        padding: 0;
        position: fixed;
        right: auto;
        left: 50px;
        top: 140px; }
        .user-menu .user-menu-tab .listing-filters select-box .select-box-elements > div:hover {
          background-color: #ff6d02;
          color: #fff; }
        .user-menu .user-menu-tab .listing-filters select-box .select-box-elements .select-element {
          transition: opacity 0.2s ease;
          padding: 0 10px;
          color: #000;
          height: 0;
          border: none;
          transition: height 0.3s ease; }
        @media only screen and (max-width: 745px) {
          .user-menu .user-menu-tab .listing-filters select-box .select-box-elements {
            top: 80px; } }
      .user-menu .user-menu-tab .listing-filters select-box.open .select-box-elements {
        width: 175px;
        height: auto; }
        .user-menu .user-menu-tab .listing-filters select-box.open .select-box-elements .select-element {
          -ms-flex-pack: start;
          border-top: solid 1px #929090;
          justify-content: flex-start;
          height: 46px;
          transition: height 0.3s ease; }
          .user-menu .user-menu-tab .listing-filters select-box.open .select-box-elements .select-element:first-child {
            border-top: none; }
    .user-menu .user-menu-tab > img {
      margin: 7px 3px 0 0; }
    .user-menu .user-menu-tab > span {
      margin-left: 5px;
      margin-right: 7px;
      max-width: calc(330px - 140px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
    .user-menu .user-menu-tab .ic-user {
      width: 38px;
      height: 38px;
      user-select: none; }
    .user-menu .user-menu-tab .icon-user {
      display: inline-block;
      height: 38px;
      width: 38px; }
  .user-menu .user-menu-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 175px;
    height: 0;
    top: 50px;
    right: -13px;
    color: #000000;
    background-color: #ebebeb;
    transition: opacity 0.2s ease;
    transition: height 0.3s ease;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.39);
    overflow: hidden;
    left: 0; }
    .user-menu .user-menu-dropdown.open-dropdown {
      height: 140px; }
      .user-menu .user-menu-dropdown.open-dropdown > div {
        opacity: 1; }
    .user-menu .user-menu-dropdown > div {
      display: flex;
      align-items: center;
      height: 45px;
      padding: 0 10px;
      border-bottom: 1px solid #929090;
      transition: opacity 0.2s ease;
      opacity: 0; }
      .user-menu .user-menu-dropdown > div:last-child {
        border-bottom: none; }
      .user-menu .user-menu-dropdown > div:hover {
        background-color: #ff740f;
        color: #ffffff; }
  .user-menu.arabic .user-menu-dropdown {
    right: 0;
    left: unset; }
  .user-menu.arabic .profile {
    flex-direction: row-reverse; }
    .user-menu.arabic .profile > span {
      margin-right: 0;
      margin-left: 14px; }
