/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 60px;
  left: 0;
  background: #00000038;
  z-index: 100;
}
/*Device width pixel lesser than 768*/
@media only screen and (max-width: 768px) {
  .overlay {
    top: 0px;
  }
}
