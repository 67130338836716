/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
 @import "app/resources/styles/variables.scss";
.menuItem {
   /*white-space: nowrap;
  overflow: hidden;
  width: calc(#{$app-menu-width} - 50px);*/
  border-radius: 30px;
 
}
.menuItem {
  display: flex;
  align-items: center;
  width:390px  !important;
  .user-icon{
    width: 60px;
    height: 60px;
    border-radius: 40px;
    padding-left: 0px;
    color: #757575;
    font: normal normal 300 16px/30px Changa;
    letter-spacing: -0.38px;
  }
  span{
   background-color: transparent !important;
   padding-left: 10px !important;
   padding-right: 10px !important;
   &:hover{
    color:#ff740f !important;
    
   }
 }
}