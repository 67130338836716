/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.select-plan-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #0d0d0d;
  padding-bottom: 144px;
  min-height: 100vh;
  padding-top: 30px; }
  .select-plan-container .select-container-text {
    color: #ffffff;
    padding-top: 50px;
    margin: 0 12%; }
  .select-plan-container .plan-selection {
    width: 80%;
    color: #ffffff;
    font-size: 14px;
    line-height: 16px;
    border: 2px solid #ff6d02;
    border-radius: 8px;
    background-color: #ff6d02;
    padding: 15px;
    margin: 0 10%;
    margin-top: 8px;
    display: flex; }
  .select-plan-container .plan-selection-big {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 76%;
    margin: 0 12%;
    height: auto;
    border-radius: 0 0 8px 8px;
    background-color: #262626; }
  .select-plan-container .select-container-billing {
    padding: 5px 0 0 10px;
    color: white;
    font-size: 14px;
    border-radius: 0 0 8px 8px;
    background-color: #262626;
    background: transparent; }
    .select-plan-container .select-container-billing .select-container-billing-info {
      margin: 10px; }
    .select-plan-container .select-container-billing .row {
      display: flex;
      justify-content: flex-start; }
      .select-plan-container .select-container-billing .row .left-column,
      .select-plan-container .select-container-billing .row .right-column {
        flex-basis: 50%; }
        .select-plan-container .select-container-billing .row .left-column .error-text,
        .select-plan-container .select-container-billing .row .right-column .error-text {
          margin: 0px 10px 0 10px;
          font-size: 12px;
          color: red; }
        .select-plan-container .select-container-billing .row .left-column input,
        .select-plan-container .select-container-billing .row .right-column input {
          width: 90%;
          margin: 10px 10px 0 10px;
          color: #d5d5d5;
          font-size: 14px; }
        .select-plan-container .select-container-billing .row .left-column .label,
        .select-plan-container .select-container-billing .row .right-column .label {
          margin: 10px; }
        .select-plan-container .select-container-billing .row .left-column .inner-column,
        .select-plan-container .select-container-billing .row .right-column .inner-column {
          display: flex;
          flex-direction: column; }
      @media only screen and (max-width: 745px) {
        .select-plan-container .select-container-billing .row {
          flex-direction: column; } }
    .select-plan-container .select-container-billing .adyen-enter-detailss {
      margin: 20px 15px; }
  .select-plan-container .input {
    background: transparent;
    border-radius: 8px;
    border: 1px solid #ccc;
    color: #ffffff; }
  .select-plan-container .labelname {
    color: #707070; }
  .select-plan-container .adyen {
    color: #707070;
    font-size: 12px;
    text-transform: uppercase;
    margin: 15px; }
    .select-plan-container .adyen input[type="radio"]:after {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      top: -1px;
      left: 0px;
      position: relative;
      background-color: #fa6d02;
      content: "";
      display: inline-block;
      visibility: visible;
      border: solid 1px #fa6d02;
      box-sizing: content-box; }
  .select-plan-container .pay-option {
    margin-top: 30px;
    text-align: center; }
    .select-plan-container .pay-option .pay-btn {
      width: 247px;
      border-radius: 5px;
      background-color: #ff6d02;
      font-size: 14px; }
  .select-plan-container .radio-div {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: solid 2px #807979;
    margin: 0 5px; }
    .select-plan-container .radio-div.selected {
      background: #fff;
      border-color: #fff; }
  .select-plan-container .radio {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: solid 2px #807979;
    margin: 0 5px;
    display: inline-block; }
    .select-plan-container .radio.selected {
      background: #ff6d02;
      border-color: #ff6d02; }

.by-clickingadyen {
  color: #ffffff;
  font-size: 12px;
  margin: 10px 231px 10px 228px; }

@media screen and (max-width: 480px) {
  .by-clickingadyen {
    margin: 9px 29px 11px 10px; } }

@media screen and (max-width: 745px) {
  .by-clickingadyen {
    margin: 9px 53px 11px 54px; } }
