/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .privacy-overlay .close-btn {
  right: auto;
  left: 20px; }

.privacy-overlay {
  width: 100%;
  min-height: calc(100vh - 50px - 186px); }
  .privacy-overlay .close-btn {
    position: absolute;
    top: 27px;
    right: 28px;
    width: 30px;
    height: 30px; }
    @media (max-width: 745px) {
      .privacy-overlay .close-btnhover {
        background-color: #ff740f; } }
    .privacy-overlay .close-btn .icon {
      top: 0; }
  .privacy-overlay .privacy-overlay-container {
    width: 100%;
    padding: 0 !important;
    background-color: #000000; }
    .privacy-overlay .privacy-overlay-container .static-page {
      padding: 0 0;
      background-color: #000000; }
      @media (max-width: 745px) {
        .privacy-overlay .privacy-overlay-container .static-page {
          padding: 0 10px; } }
      .privacy-overlay .privacy-overlay-container .static-page .overlay-title {
        color: white !important;
        max-width: 1300px;
        font-size: 81px !important;
        font-family: changa;
        width: 100%;
        margin: 0 auto !important;
        background-color: #000000 !important;
        padding: 22px 0 !important;
        font-weight: bold !important;
        text-align: center;
        margin-bottom: 26px; }
        @media (max-width: 767px) {
          .privacy-overlay .privacy-overlay-container .static-page .overlay-title {
            font-size: 26px !important; } }
      .privacy-overlay .privacy-overlay-container .static-page .static-content {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        background-color: #000000 !important;
        color: #6c6c6c;
        padding: 20px 60px;
        font-family: changa; }
        .privacy-overlay .privacy-overlay-container .static-page .static-content h4 {
          font-size: 24px !important;
          margin-bottom: auto; }
        @media (max-width: 745px) {
          .privacy-overlay .privacy-overlay-container .static-page .static-content {
            padding: 15px;
            margin-top: -44px !important; } }
        .privacy-overlay .privacy-overlay-container .static-page .static-content ul li {
          font: normal normal 200 21px/36px Changa !important;
          line-height: 30px !important; }
          @media (max-width: 767px) {
            .privacy-overlay .privacy-overlay-container .static-page .static-content ul li {
              font-size: 16px !important; } }
        .privacy-overlay .privacy-overlay-container .static-page .static-content h1 {
          font: normal normal bold 26px/28px Changa;
          letter-spacing: 0px;
          color: #707070; }
          @media (max-width: 767px) {
            .privacy-overlay .privacy-overlay-container .static-page .static-content h1 {
              font: normal normal bold 16px/16px Changa; } }
        .privacy-overlay .privacy-overlay-container .static-page .static-content h3 {
          margin: -30px 0 0 0;
          font-weight: lighter !important; }
          @media (max-width: 767px) {
            .privacy-overlay .privacy-overlay-container .static-page .static-content h3 {
              margin: -15px 0 0 0; } }
          .privacy-overlay .privacy-overlay-container .static-page .static-content h3 strong {
            font-family: changa;
            font-size: 26px;
            color: #EE7623; }
            @media (max-width: 767px) {
              .privacy-overlay .privacy-overlay-container .static-page .static-content h3 strong {
                font-size: 24px !important; } }
        .privacy-overlay .privacy-overlay-container .static-page .static-content span {
          font: normal normal bold 26px/25px Changa;
          color: #707070; }
          @media (max-width: 767px) {
            .privacy-overlay .privacy-overlay-container .static-page .static-content span {
              font-size: 24px !important; } }
        .privacy-overlay .privacy-overlay-container .static-page .static-content p {
          font-size: 26px;
          font-family: changa;
          letter-spacing: 0px;
          line-height: 30px; }
          @media (max-width: 767px) {
            .privacy-overlay .privacy-overlay-container .static-page .static-content p {
              font-size: 16px !important;
              text-align: left;
              font: normal normal normal 16px/16px Changa;
              letter-spacing: 0px;
              color: #707070; } }
          .privacy-overlay .privacy-overlay-container .static-page .static-content p span {
            font-weight: bold; }
        .privacy-overlay .privacy-overlay-container .static-page .static-content strong {
          font-family: changa;
          font-weight: normal !important;
          font-size: 26px;
          color: #EE7623; }
          @media (max-width: 767px) {
            .privacy-overlay .privacy-overlay-container .static-page .static-content strong {
              font-size: 17px !important;
              line-height: 27px; } }
      .privacy-overlay .privacy-overlay-container .static-page.arabic {
        direction: rtl; }
  .privacy-overlay .overlays-close {
    position: fixed;
    top: 65px;
    right: 15px;
    display: inline-block;
    width: 25px;
    height: 25px;
    overflow: hidden;
    cursor: pointer; }
    .privacy-overlay .overlays-close::before, .privacy-overlay .overlays-close::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: white; }
    .privacy-overlay .overlays-close::before {
      transform: rotate(45deg); }
    .privacy-overlay .overlays-close::after {
      transform: rotate(-45deg); }

.overlays.ng-enter {
  transition: 0.3s ease-in-out all;
  opacity: 0; }

.overlays.arabic .overlays-close {
  position: fixed;
  right: auto;
  left: 15px; }

.overlays.ng-enter.ng-enter-active {
  opacity: 1; }

.privacy-title span {
  color: #EE7623 !important; }

@media (max-width: 767px) {
  .privacy-overlay .privacy-overlay-container .static-page .static-content p span {
    font-weight: bold;
    font-size: 16px !important; } }

@media (max-width: 767px) {
  .privacy-overlay .privacy-overlay-container .static-page .static-content h1 strong {
    font-weight: bold;
    font-size: 18px !important; } }

@media (max-width: 767px) {
  .rtl .privacy-overlay .privacy-overlay-container .static-page .static-content p span {
    font-weight: bold;
    font-size: 16px !important; }
  .rtl .privacy-overlay .privacy-overlay-container .static-page .static-content p {
    text-align: justify; }
  .rtl span u {
    font-size: 16px !important;
    margin-bottom: -25px;
    font-weight: bold; } }

@media (max-width: 767px) {
  .privacy-overlay .privacy-overlay-container .static-page .static-content h4 {
    font-size: 16px !important;
    margin-bottom: -25px;
    font-weight: bold; } }

@media (max-width: 767px) {
  .privacy-overlay .privacy-overlay-container .static-page .static-content h2 {
    text-align: left; }
  .privacy-overlay .privacy-overlay-container .static-page .static-content ul li {
    text-align: left; } }

@media (max-width: 767px) {
  .rtl .privacy-overlay .privacy-overlay-container .static-page .static-content h1 p {
    margin-bottom: -20px; }
  .rtl .privacy-overlay .privacy-overlay-container .static-page .static-content ul li {
    text-align: justify; } }

@media (max-width: 767px) {
  .privacy-overlay .privacy-overlay-container .static-page .static-content ul li {
    font-size: 16px !important;
    line-height: 20px !important; } }
