.select-box-container {
  margin: 10px 0 10px 30px;
  display: -ms-inline-flexbox;
  display: inline-flex;

  .select-box {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 250px;
    cursor: pointer;
    background-color: grey;
    color: #000;
    .selected,
    .select-element {
      line-height: 46px;
      height: 46px;
      color:#9993a0;
      font-family: 'changa-regular';
      background-color: #ebebeb;

      &:hover {
        color: #ff6d02;
      }
    }
    .selected {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    &.single {
      cursor: unset;
      user-select: none;
      .selected {
        &:hover {
          color: #ff6d02;
        }
      }
    }
  }

  label {
    height: 46px;
    line-height: 46px;
    float: left;
  }

  .selected {
    border: none;
    padding-left: 10px;
    padding-right: 10px;

    .btn {
      .icon {
        width: 25px;
        height: auto;
        transition: transform 0.2s ease;
        top: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
}

select-box {
  .select-box-elements {
    height: 0px;
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    top: 46px;
    padding: 0 10px;
    transition: height 0.3s ease;
    background-color: #ebebeb;
    overflow: hidden;
    transition: height 0.3s ease;
  }
  .select-element {
    transition: opacity 0.2s ease;
    border-top: solid 1px rgba(255, 255, 255, 0.75);
    opacity: 0;
  }

  &.open {
    .select-box-scroll {
      overflow-y: scroll;
      max-height: 35vh;
    }
    .select-box-elements {
      height: auto;
      z-index: 1;
    }
    .select-element {
      opacity: 1;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    .btn {
      .icon {
        transform: rotate(180deg);
      }
    }
  }
}
