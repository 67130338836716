/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
@supports not (-ms-high-contrast: none) {
  .app-body[dir="rtl"] {
    /*Apply to other browsers but IE */ }
    .app-body[dir="rtl"] .first-column .featured-grid-carousel-container {
      margin-top: 4px; }
    .app-body[dir="rtl"] .featured-item.desk4Img, .app-body[dir="rtl"] .featured-item.desk5Img {
      margin-top: 4px; } }

.LayoutD-Container {
  text-align: center;
  position: relative; }
  @media (max-width: 767px) {
    .LayoutD-Container {
      text-align: center;
      position: relative;
      top: -7.5em;
      z-index: 1; } }

.LayoutD-containerAr {
  text-align: center;
  position: relative; }
  @media (max-width: 767px) {
    .LayoutD-containerAr {
      text-align: center;
      position: relative;
      top: -7.5em;
      z-index: 1; } }

.synopsis-title {
  width: auto;
  height: auto;
  display: block;
  bottom: -15px;
  position: absolute;
  left: 95px !important; }
  @media (max-width: 767px) {
    .synopsis-title {
      width: auto;
      height: auto;
      display: block;
      position: absolute;
      left: 50px !important; } }

.synopsis-titleAr {
  width: auto;
  height: auto;
  display: block;
  bottom: -15px !important;
  position: absolute;
  right: 100px; }
  @media (max-width: 745px) {
    .synopsis-titleAr {
      display: block;
      bottom: -40px !important;
      align-items: center;
      text-align: center; } }

.gradient1 {
  background: transparent linear-gradient(180deg, rgba(15, 24, 34, 0) 27%, #0f1822 67%) 83% 24% no-repeat padding-box;
  width: 100%;
  height: 596px;
  top: 28em;
  position: absolute; }
  @media (max-width: 767px) {
    .gradient1 {
      background: transparent linear-gradient(179deg, #0f182200 27%, #0f1822 67%) 83% 24% no-repeat padding-box;
      width: 100%;
      height: 96px;
      top: 34em;
      position: absolute; } }

.bottom-left-images,
.bottom-left-imagesAr {
  color: white;
  display: flex;
  align-items: center; }

.bottom-left-imagesAr {
  left: auto; }
  @media (max-width: 767px) {
    .bottom-left-imagesAr {
      right: 177px;
      left: auto;
      position: absolute;
      bottom: 15%; } }

.new-banner-contentype1 {
  color: white;
  margin: -1px 9px 0px 10px;
  white-space: nowrap;
  font: normal normal medium 20px/21px Changa;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .new-banner-contentype1 {
      color: white;
      font-size: 15px; } }

.bottom-left,
.bottom-leftAr {
  color: #ffffff;
  font: normal normal bold 29px / 21px Changa;
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.contentTitleEn {
  font: normal normal bold 23px / 30px Changa;
  white-space: nowrap;
  text-align: left; }
  @media (max-width: 767px) {
    .contentTitleEn {
      font-size: 16px;
      margin-bottom: 0px;
      position: absolute;
      top: 5em; } }

.contentTitleEnAR {
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font: normal normal bold 60px / 21px Changa; }

.contentTitleArabic {
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font: normal normal bold 60px / 21px Changa; }

.titleLogoContainerAr {
  display: flex;
  align-items: center;
  justify-content: center;
  display: contents; }

.titleLogoContainer {
  align-items: center;
  justify-content: center;
  position: relative; }

.titleLogoImage,
.titleLogoImageAr {
  height: 100px;
  width: auto;
  display: block; }

.titleLogoImage {
  height: 100px;
  width: auto;
  display: block;
  position: relative; }

.titleLogoImages {
  height: 100px;
  width: 100vw; }

@media (max-width: 767px) {
  .titleLogos {
    height: 100px;
    width: 200px;
    left: unset;
    right: unset; } }

@media (max-width: 767px) {
  .titleLogosAr {
    height: 100px;
    width: 200px; } }

.new-banner-contentimage {
  display: flex;
  align-items: center;
  padding: 20px 10px; }

.title-images1 {
  filter: brightness(0) invert(1);
  height: 15px; }

.title-imagesAr {
  filter: brightness(0) invert(1);
  color: white; }

.bottom-left-synopsis {
  color: #FFFFFF;
  overflow: hidden;
  text-align: left;
  font-family: "Changa-Regular";
  line-height: 25px;
  width: 496px;
  font-size: 18px; }
  @media (max-width: 745px) {
    .bottom-left-synopsis {
      display: none; } }
  @media (max-width: 600px) {
    .bottom-left-synopsis .bottom-left-synopsis {
      display: none; } }

.bottom-left-synopsisAr {
  position: absolute;
  color: #ffffff;
  overflow: hidden;
  font-size: 18px;
  font-family: "Changa-Regular";
  line-height: 25px;
  white-space: pre-wrap;
  text-align: right;
  width: 496px;
  position: absolute;
  top: 145px; }
  @media (max-width: 745px) {
    .bottom-left-synopsisAr {
      display: none; } }

.watchMore_Button {
  display: flex;
  align-items: center;
  position: relative;
  height: 52px;
  grid-gap: 30px;
  gap: 30px;
  cursor: pointer;
  z-index: 1;
  margin-top: 2rem; }

.watchMore_ButtonAr {
  display: flex;
  align-items: center;
  position: relative;
  height: 52px;
  margin-top: 6rem;
  gap: 30px;
  z-index: 1; }

.watchNow {
  left: 75px;
  width: 195px;
  height: 44px;
  background: transparent linear-gradient(180deg, var(--unnamed-color-ee7623) 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #ee7623 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
  background-size: cover;
  text-align: center;
  font: normal normal bold 18px/33px Changa;
  letter-spacing: 0px;
  color: #ffffff;
  border-radius: 30px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  @media (max-width: 767px) {
    .watchNow {
      width: 159px;
      height: 34px;
      background: transparent linear-gradient(180deg, var(--unnamed-color-ee7623) 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
      background: transparent linear-gradient(180deg, #ee7623 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
      background-size: cover;
      text-align: center;
      font: normal normal bold 15px / 33px Changa;
      letter-spacing: 0px;
      color: #ffffff;
      border-radius: 30px;
      border: none;
      cursor: pointer; } }

.watchNowAr {
  right: 50px;
  width: 195px;
  height: 44px;
  background: transparent linear-gradient(180deg, var(--unnamed-color-ee7623) 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #ee7623 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
  background-size: cover;
  text-align: center;
  font: normal normal bold 15px/33px Changa;
  letter-spacing: 0px;
  color: #ffffff;
  border-radius: 30px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  @media (max-width: 767px) {
    .watchNowAr {
      width: 171px;
      height: 34px;
      background: transparent linear-gradient(180deg, var(--unnamed-color-ee7623) 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
      background: transparent linear-gradient(180deg, #ee7623 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
      background-size: cover;
      text-align: center;
      font: normal normal bold 14px / 33px Changa;
      letter-spacing: 0px;
      color: #ffffff;
      border-radius: 30px;
      border: none;
      cursor: pointer;
      white-space: nowrap; } }

.morebutton {
  left: 296px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: white;
  height: 43px;
  border: none;
  outline: none;
  cursor: pointer; }
  @media (max-width: 767px) {
    .morebutton {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      color: white;
      height: 34px;
      border: none;
      outline: none;
      cursor: pointer; } }

.morebuttonAr {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: white;
  height: 43px;
  border: none;
  outline: none;
  cursor: pointer; }
  @media (max-width: 767px) {
    .morebuttonAr {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      color: white;
      height: 34px;
      border: none;
      outline: none;
      cursor: pointer; } }

.Carousel_ImageAr {
  display: flex;
  margin: -30% 80% 1% 80%;
  position: relative;
  top: 7em;
  cursor: pointer;
  z-index: 1; }
  @media (max-width: 767px) {
    .Carousel_ImageAr {
      display: flex;
      margin: -9% 25% 0% 19%;
      cursor: pointer; } }

.firstFoldedBanner {
  padding: 10px;
  position: relative;
  top: 7em !important; }
  @media (max-width: 1440px) {
    .firstFoldedBanner {
      padding: 10px;
      position: relative;
      top: 11em !important; } }
  @media (max-width: 767px) {
    .firstFoldedBanner {
      z-index: 1;
      position: relative;
      top: 13em !important; } }

.btns {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  background: grey; }

.thumbnail {
  display: flex;
  flex-direction: row;
  margin-left: 10px; }

.thumbnailAr {
  display: flex;
  flex-direction: row;
  margin-right: 10px; }

.homePageSlider {
  height: 1080px;
  width: 100vw; }

@media (max-width: 745px) {
  .homePageSlider {
    height: 420px !important;
    width: 100vw;
    transform: scale(1.2) translate(-6%, 34%);
    object-fit: cover; }
  .bottom-left {
    left: 50px;
    z-index: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    top: 2em; }
  .contentTitleEnAR {
    letter-spacing: 0px;
    color: #ffffff;
    position: relative;
    opacity: 1;
    white-space: normal;
    font: normal normal bold 23px / 21px Changa;
    display: block;
    z-index: 1;
    display: -webkit-box;
    word-wrap: break-word; }
  .contentTitleArabic {
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    white-space: normal;
    font: normal normal bold 23px / 21px Changa;
    display: block;
    z-index: 1;
    display: -webkit-box;
    line-height: 1.2;
    max-height: 2.4em; }
  .watchMore_Button,
  .watchMore_ButtonAr {
    display: flex;
    align-items: center;
    position: relative;
    height: 52px;
    z-index: 1; }
  .titleLogoImage {
    height: 65px;
    width: auto;
    display: block;
    position: absolute;
    bottom: -5em;
    left: 44px;
    z-index: 1;
    transform: translateX(-18%); }
  .titleLogoImageAr {
    height: 65px;
    width: auto;
    display: block;
    position: absolute;
    bottom: -4em;
    left: -8em;
    z-index: 1;
    transform: translateX(-18%); } }

.Carousel_Image {
  display: flex;
  margin: -28% 16% 1% 97%;
  position: relative;
  top: 7em;
  cursor: pointer;
  z-index: 1;
  justify-content: flex-end; }

.Carousel_ImageAr {
  display: flex;
  margin: -30% 97% 1% 80%;
  position: relative;
  top: 7em;
  cursor: pointer;
  justify-content: flex-end; }

.carouselClicked {
  width: 20px;
  height: 5px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 1px;
  background: #c0c0c0; }

.carouselClicked.clicked {
  background: #ec1c24;
  width: 40px; }

@media (max-width: 745px) {
  .carouselClicked.clicked {
    background: #ec1c24;
    width: 25px; } }

@media screen and (max-width: 1440px) and (min-width: 746px) {
  .homePageSlider {
    width: 100%;
    height: auto;
    object-fit: cover; }
  .synopsis-title {
    width: auto;
    height: auto;
    display: block;
    bottom: -144px;
    position: absolute;
    left: 110px !important; }
  .synopsis-titleAr {
    width: auto;
    height: auto;
    display: block;
    bottom: -95px !important;
    position: absolute;
    right: 64px; }
  .gradient1 {
    background: transparent linear-gradient(180deg, rgba(15, 24, 34, 0) 27%, #0f1822 67%) 83% 24% no-repeat padding-box;
    width: 100%;
    height: 744px;
    top: 11em;
    position: absolute; }
  .Carousel_Image {
    display: flex;
    position: relative;
    top: 10em;
    cursor: pointer;
    z-index: 1;
    justify-content: flex-end; }
  .watchMore_Button {
    display: flex;
    align-items: center;
    position: relative;
    height: 52px; }
  .watchMore_ButtonAr {
    display: flex;
    align-items: center;
    position: relative;
    height: 52px; }
  .titleLogoContainerAr {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
  .bottom-left-synopsis {
    left: 80px;
    color: white;
    overflow: hidden;
    text-align: justify;
    font-family: "Changa-Regular";
    line-height: 25px;
    z-index: 1; }
  .bottom-left-synopsisAr {
    position: absolute;
    color: white;
    overflow: hidden;
    text-align: justify;
    font-family: "Changa-Regular";
    line-height: 25px;
    z-index: 1; }
  .bottom-left-images {
    color: white;
    display: flex;
    align-items: center; }
  .bottom-left-imagesAr {
    color: white;
    display: flex;
    align-items: center;
    width: 10px;
    white-space: nowrap; }
  .Carousel_ImageAr {
    display: flex;
    position: relative;
    top: 3em;
    cursor: pointer;
    justify-content: flex-end; } }

@media (max-width: 767px) {
  .titleLogoContainer {
    display: flex;
    width: auto;
    z-index: 1; }
  .titleLogoContainerAr {
    display: flex;
    width: auto;
    z-index: 1; }
  .carouselClicked.clicked {
    background: #ec1c24;
    width: 25px; }
  .Carousel_Image {
    cursor: pointer;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    top: 12em;
    z-index: 1;
    margin: 0 auto;
    width: 100%; }
  .thumbnail,
  .thumbnailAr {
    display: flex;
    flex-direction: row;
    margin-left: 0 5px; }
  .Carousel_ImageAr {
    margin: -38% 25% 1% 74%;
    cursor: pointer;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    top: 13em;
    z-index: 1;
    margin: 0 auto;
    width: 100%; }
  .titleLogoImage,
  .titleLogoImageAr {
    height: 100px;
    width: auto;
    display: block; }
  .watchMore_Button {
    display: flex;
    align-items: center;
    position: relative;
    top: 3em;
    grid-gap: 8px;
    gap: 8px;
    left: 50px; }
  .watchMore_ButtonAr {
    display: flex;
    align-items: center;
    position: relative;
    top: 8em;
    gap: 8px; }
  .bottom-left-synopsis {
    position: absolute;
    bottom: 18%;
    left: 80px;
    color: white;
    overflow: hidden;
    width: 54%;
    font: normal normal normal 19px / 30px Changa;
    text-align: justify; }
  .bottom-left-synopsisAr {
    position: absolute;
    bottom: 9%;
    right: 52px;
    color: white;
    display: -webkit-box;
    overflow: hidden;
    width: 45%;
    font: normal normal normal 18px / 30px Changa; }
  .bottom-leftAr {
    right: unset;
    z-index: 1;
    top: 12em;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    top: 7.5em; }
  .bottom-left-images {
    position: absolute;
    left: 90px;
    display: flex;
    align-items: center;
    z-index: 1;
    top: 1.5em; }
  .bottom-left-imagesAr {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1;
    right: 50px; }
  .gradient2 {
    background: transparent linear-gradient(178deg, #0f182200 27%, #0f1822 67%) 83% 24% no-repeat padding-box;
    width: 100%;
    height: 557px;
    top: 9em;
    position: absolute; }
  .gradient2Ar {
    background: transparent linear-gradient(178deg, #0f182200 27%, #0f1822 67%) 83% 24% no-repeat padding-box;
    width: 100%;
    height: 557px;
    top: 9em;
    position: absolute; } }
