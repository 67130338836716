/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .rodal-dialog .actions .dialog-ok-btn:first-child {
  border-right: none;
  border-left: 1px solid #606060; }

.manage-account {
  background-color: black;
  display: flex;
  justify-content: center; }

.active-text {
  margin: 0 10px; }

.manage-account-conatiner {
  background: black;
  color: #fff;
  min-height: calc(100vh - 80px - 185px);
  width: 85%;
  margin: 30px 0 70px;
  font-family: "Changa"; }
  .manage-account-conatiner .subscription-title {
    text-align: center;
    font-size: 20px;
    padding: 20px; }
  .manage-account-conatiner .tab-buttons {
    display: flex;
    justify-content: center;
    margin: 50px 0 20px; }
    @media only screen and (max-width: 745px) {
      .manage-account-conatiner .tab-buttons {
        margin: 20px 0; } }
    .manage-account-conatiner .tab-buttons .btn {
      font-size: 16px;
      width: 197px;
      height: 41px;
      border-radius: 5px;
      background-color: #4a4a4a; }
      .manage-account-conatiner .tab-buttons .btn.current {
        background-color: #ff6d02; }
  .manage-account-conatiner .heading {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 30px;
    line-height: 30px;
    color: white;
    font-weight: 700;
    text-transform: capitalize; }
  .manage-account-conatiner .plan-item {
    display: flex;
    margin: 10px 0;
    color: #4a4a4a;
    border-radius: 5px;
    height: 25px;
    font-size: 14px;
    line-height: 25px; }
    .manage-account-conatiner .plan-item .label {
      width: 150px; }
      @media only screen and (max-width: 745px) {
        .manage-account-conatiner .plan-item .label {
          width: 125px; } }
  .manage-account-conatiner .active .plan-item .value {
    color: white; }
  .manage-account-conatiner .add-plan {
    margin: 15px 0 30px;
    font-size: 16px;
    width: 93px;
    height: 37px;
    border-radius: 5px;
    background-color: #ff6d02; }
  .manage-account-conatiner .cancel-subscription {
    height: 39px;
    color: #ff6d02;
    font-size: 14px;
    line-height: 32px;
    text-decoration: underline; }
    .manage-account-conatiner .cancel-subscription span {
      cursor: pointer; }
  .manage-account-conatiner .cancelled {
    color: #4a4a4a;
    height: 39px;
    font-size: 14px;
    line-height: 32px;
    text-decoration: underline; }
  .manage-account-conatiner .toggle-button {
    margin: 4px;
    display: flex;
    justify-content: flex-end;
    width: 36px;
    height: 16px; }
    .manage-account-conatiner .toggle-button .react-switch-bg {
      border: 1px solid #979797; }
  .manage-account-conatiner .horizontal-divider {
    width: 100%;
    height: 1px;
    background: #424242; }

.rodal-dialog {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .rodal-dialog .dialog-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .rodal-dialog .dialog-content .content {
      padding: 24px;
      text-align: center; }
    .rodal-dialog .dialog-content .dialog-title {
      color: #ff740f;
      margin-top: 20px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
      text-align: center; }
    .rodal-dialog .dialog-content .dialog-text {
      color: #606060; }
  .rodal-dialog .actions {
    display: flex;
    height: 36px; }
    .rodal-dialog .actions .dialog-ok-btn {
      border-top: 1px solid #606060;
      width: 100%;
      text-transform: none;
      color: #606060;
      padding: 0;
      margin: 0 0 -17px;
      border-radius: 0;
      background: #fff;
      font-size: 14px;
      height: 36px; }
      .rodal-dialog .actions .dialog-ok-btn:first-child {
        border-right: 1px solid #606060; }
      .rodal-dialog .actions .dialog-ok-btn:hover {
        background: #ff740f;
        color: white; }
  @media only screen and (max-width: 745px) {
    .rodal-dialog {
      width: 90% !important; } }

.no-active-plan {
  text-align: center;
  color: #fff;
  width: 100%;
  padding: 30px; }

.subscribe-now-button {
  margin: 50px 0;
  width: 158px;
  height: 46px;
  border-radius: 8px;
  background-color: #ff6d02;
  font-size: 18px; }
  @media only screen and (max-width: 745px) {
    .subscribe-now-button {
      margin: 25px 0;
      width: 230px;
      height: 37px;
      border-radius: 5px;
      font-size: 14px; } }
