@import "videojs/video-js.min.css";
@import "videojs/video-js.ima.css";

.desktop {
  #zplayer_ima-mute-div,
  #zplayer_ima-slider-div {
    display: none !important;
  }
}

.mobile {
  #zplayer_ima-controls-div {
    display: none !important;
  }

  #zplayer_ima-mute-div,
  #zplayer_ima-slider-div {
    display: none !important;
  }
}

.vpaid {
  #zplayer_ima-controls-div {
    display: block !important;
  }
}

.rodal-dialog .dialog-content .dialog-title{
    text-align: center;
    margin: 30px 2px !important;
    color: #606060 !important;
    font-size: 20px !important;
    // letter-spacing: 0.005em ;
    font-family: "changa"
}
.skip-introo {
  position: absolute;
  left: 90px;
  width: 159px;
  height: 55px;
  bottom: 120px;
  border: 2px solid #ff6d02;
  border-radius: 10px;
  opacity: 1;
  background: transparent;
  color: #ff6d02;
  font:normal normal bold 16px/30px Changa;
  letter-spacing: 0px;
  text-align: center;
}
.check-intro, .checkintrofullscreen {
  position: absolute;
  left: 5%;
  width: 159px;
  height: 55px;
  bottom: 100px;
  border: 2px solid #EE7623;
  border-radius: 10px;
  opacity: 1;
  background: transparent;
  background-color: #EE7623;
  color:#ffffff;
  font: normal normal bold 16px/30px Changa ;
  letter-spacing: 0px;
  text-align: center;
  z-index: 999;
}
.check-outro, .checkoutrofullscreen {
  position: absolute;
  width: 159px;
  height: 55px;
  bottom: 100px;
  border: 2px solid #EE7623;
  border-radius: 10px;
  opacity: 1;
  background: transparent;
  background-color: #EE7623;
  font:normal normal bold 16px/30px Changa;
  letter-spacing: 0px;
  text-align: center;
  right: 5%;
  z-index: 999;
  overflow: hidden;
}
.checkoutrofullscreen .next-episode-title {
  position: relative;
  z-index: 9;
  color: #ffffff;
}


@media (max-width: 1024px) {
  .check-intro{
    bottom: 0px;
    left: 20px;
    height: 39px;
  }
  .check-intro, .checkintrofullscreen {
    // left: 20px;
    // bottom: 15px;
    // bottom:70px;
    height: 39px;
    font-size: 12px;
    font-weight: bold !important;
    width: 111px;
  }
  .check-outro{
    height: 39px;
    bottom: 0px;
    right: 20px;
  }
  .check-outro, .checkoutrofullscreen {
    // right: 20px;
    // bottom: 15px;
    height: 39px;
    font-size: 12px;
    font-weight: bold !important;
    width: 111px;
  }
}

  @media (max-width: 936px) {
    .check-intro {
      left: 10px;
      bottom: -10px;
    }
    .check-outro {
      right: 10px;
      bottom: -10px;
    }

    .checkintrofullscreen {
      bottom:70px;
    }
    .checkoutrofullscreen {
      bottom:70px;
    }
  }
  @media (max-width: 768px) {
    .check-intro {
      left: 27px;
      bottom: -10px;
    }
    .check-outro {
      right: 27px;
      bottom: -10px;
    }

    .checkintrofullscreen {
      bottom:70px;
    }
    .checkoutrofullscreen {
      bottom:70px;
    }
  }
  .outro-play {
    animation-play-state: running !important;
  }

  .outro-pause {
    animation-play-state: paused !important;
  }
  .rtl .outro{
    left:inherit;
    right:0px; 
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
 }
 .outro {
  position: absolute;
  left: 0px;
  color: #ffffff;
  background-color: #8d3c02;
 -webkit-animation: progres 4s infinite linear;
 animation: progres 120s infinite linear;
  display: block;
  height: 100%;
  top: 0px;
  border-radius: 7px;
  line-height: 50px;
  border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}